import { ds_MicrositeHomepage_Partial } from '../shared/ds_MicrositeHomepage_Partial'

export const ds_MicrositePage_Entry = `
    fragment ds_MicrositePage_Entry on ds_MicrositePage_Entry {
        canonicalUid
        typeHandle
        hideBanner
        bannerImage {
            url @transform(width: 1920, height: 1080)
            focalPoint
        }
        popup {
            canonicalUid
        }
        preFooter {
            canonicalUid
        }
        contentBuilder {
            __typename
        }
        ancestors(level: 1) {
            ...ds_MicrositeHomepage_Partial
        }
    }
    ${ds_MicrositeHomepage_Partial}
`
