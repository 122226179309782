import revive_payload_client_T3JtUTmYld from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XDSAREfuny from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UNDstiIqVJ from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2ewLTSa3oL from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4._uph6ll44kyrvttjwwy7lhbb6yu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_m9uF8TIQ8X from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_STDqKI4D71 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oi5WQ0lfRD from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5BRXcOQy9l from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6CskmDLhsW from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.30.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-frontend/web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_U0cUzXoJf5 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_dsqf7ion5gxmijrip5cbulcefm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8Z7zeCfIye from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0gQPZwB2mV from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Dv3yfoisgS from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.30.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_6pHtkodynl from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.30.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_craft_sclci9JHWi from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-craft.ts";
import apollo_error_handler_a0IiUcX0mN from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-error-handler.ts";
import crypto_IPXw5s4rs8 from "/home/runner/work/web-frontend/web-frontend/plugins/crypto.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/web-frontend/web-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_T3JtUTmYld,
  unhead_XDSAREfuny,
  router_UNDstiIqVJ,
  _0_siteConfig_2ewLTSa3oL,
  payload_client_m9uF8TIQ8X,
  navigation_repaint_client_STDqKI4D71,
  check_outdated_build_client_oi5WQ0lfRD,
  chunk_reload_client_5BRXcOQy9l,
  plugin_vue3_6CskmDLhsW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_U0cUzXoJf5,
  switch_locale_path_ssr_8Z7zeCfIye,
  i18n_0gQPZwB2mV,
  plugin_Dv3yfoisgS,
  plugin_6pHtkodynl,
  apollo_craft_sclci9JHWi,
  apollo_error_handler_a0IiUcX0mN,
  crypto_IPXw5s4rs8,
  sentry_client_shVUlIjFLk
]